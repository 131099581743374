<template>
  <b-modal
    id="confirm-nf-appointment-modal"
    size="md"
    hide-header
    hide-footer
    content-class="modal__container"
  >
    <div class="modal-header">
      <div class="modal-title">Confirmar Agendamento</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <p class="p-3 modal__content">
      Data selecionada {{ moment(this.scheduleDate).format('DD/MM/YYYY') }} não
      corresponde à data de hoje {{ moment().format('DD/MM/YYYY') }},
      <span> você deseja confirmar o agendamento dessa nota fiscal? </span>
    </p>

    <div class="my-4 d-flex justify-content-end">
      <b-button
        variant="outline-primary"
        class="mr-3"
        @click="cancelScheduleIssue"
      >
        Cancelar
      </b-button>

      <b-button variant="primary" @click="confirmIssue"> Confirmar </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ConfirmNfAppointmentModal',
  props: {
    scheduleDate: Date
  },
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  computed: {
    ...mapState('nfe', ['isScheduledInvoice'])
  },
  methods: {
    cancelScheduleIssue() {
      this.$emit('cancelScheduleIssue')
      this.closeModal()
    },
    confirmIssue() {
      this.$store.dispatch('nfe/updateIsScheduledInvoice', true)
      this.closeModal()
    },
    closeModal() {
      this.$bvModal.hide('confirm-nf-appointment-modal')
    }
  }
}
</script>

<style lang="scss">
#confirm-nf-appointment-modal {
  .modal-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans';
    color: var(--type-active);
  }

  .icon-box {
    width: 24px;

    svg {
      fill: var(--neutral-500);
    }
  }

  .modal__container {
    margin-top: 12rem;
  }

  .modal__content {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Nunito Sans';
    color: var(--type-active);

    > span {
      font-weight: 700;
      color: var(--blue-500);
    }
  }
}
</style>
